<template>
  <div>
    <component-layout :title="'JOBS LIST'">
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <router-link
              style="color: #000"
              class="text-decoration-none"
              :to="{ path: '/job/create' }"
            >
              <v-list-item v-ripple="{ center: true }">
                ADD JOB
              </v-list-item>
            </router-link>
          </v-list>
        </v-menu>
      </template>
      <JobLayout
        @filter="filter"
        :filterCriteriaProps="filterCriteria"
        :hasFilter="false"
      >
        <div class="d-flex justify-end pb-5">
          <v-btn class="mr-2" @click="$router.push({ name: 'jobs' })">
            <v-icon>mdi-format-list-checkbox</v-icon>
          </v-btn>
          <v-btn class="mr-2" @click="$router.push({ name: 'jobs.grid-view' })">
            <v-icon>mdi-grid</v-icon>
          </v-btn>
        </div>
        <table class="table" style="width: 100%">
          <thead>
            <tr class="table-heading">
              <th scope="col" class="text-start px-3 py-2 ">Job No</th>
              <th scope="col" class="text-start px-3 py-2">Job Title</th>
              <th scope="col" class="text-start px-3 py-2">Job Priority</th>
              <th scope="col" class="text-start px-3 py-2">Job Type</th>
              <th scope="col" class="text-start px-3 py-2">Job Status</th>
              <th scope="col" class="text-start px-3 py-2">Start/Due Date</th>
              <th scope="col" class="text-start px-3 py-2">Customer Name</th>
              <th scope="col" class="text-start px-3 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="mt-3" v-for="item in jobList" :key="item.id">
              <router-link
                :to="'/jobs/detail/' + item.id"
                class="text-decoration-none"
                ><td>{{ item.job_no }}</td></router-link
              >
              <td>{{ item.job_title }}</td>
              <td>
                <span
                  class="preview"
                  :style="
                    'background: ' +
                      (item.priority != null
                        ? item.priority.background_color
                        : '') +
                      ';color: ' +
                      (item.priority != null ? item.priority.text_color : '')
                  "
                  >{{ item.priority ? item.priority.title : "" }}</span
                >
              </td>
              <td>
                <span
                  class="preview"
                  :style="
                    'background: ' +
                      (item.job_type != null
                        ? item.job_type.background_color
                        : '') +
                      ';color: ' +
                      (item.job_type != null ? item.job_type.text_color : '')
                  "
                  >{{ item.job_type ? item.job_type.title : "" }}</span
                >
              </td>
              <td>
                <span
                  class="preview"
                  :style="
                    'background: ' +
                      (item.job_status != null
                        ? item.job_status.background_color
                        : '') +
                      ';color: ' +
                      (item.job_status != null
                        ? item.job_status.text_color
                        : '')
                  "
                  >{{ item.job_status ? item.job_status.title : "" }}</span
                >
              </td>
              <td>
                {{ item.start_date_formatted }} /
                {{ item.due_date_formatted }}
              </td>
              <td>{{ item.customer ? item.customer.full_name : "" }}</td>

              <td>
                <router-link :to="'/job/' + item.id + '/edit/'">
                  <button class="btn mr-3 edit-btn`">
                    <i class="fa fa-pencil edit-btn"></i></button
                ></router-link>
                <button class="delete-btn" v-if="!item.is_billed" @click="deletePopup(item)">
                  <i class="fa fa-trash delete-btn"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </JobLayout>
      <v-dialog v-model="showDeleteModal" v-if="id" max-width="450px">
        <deleteDialog
          resource="job"
          :id="id"
          @cancel="cancel"
          @reload-resource="reloadResource"
        />
      </v-dialog>
    </component-layout>
  </div>
</template>

<script>
import util from "@/util/util";
import componentLayout from "@/components/component-layout.vue";
import JobLayout from "@/components/Jobs/Job-Layout.vue";
import deleteDialog from "@/components/shared/delete-modal";

export default {
  data() {
    return {
      showDeleteModal: false,
      jobList: [],
      id: null,
      filterCriteria: {
        length: 1,
        page: 1,
        q: "",
        job_type: null,
        job_status: null,
        job_priority: null,
        start_date: null,
        end_date: null,
        customer: null,
      },
    };
  },
  components: {
    componentLayout,
    JobLayout,
    deleteDialog
  },
  mounted() {
    this.getJobList();
  },
  methods: {
    getJobList() {
      util
        .http({
          url: "/job/get-all",
          params: this.filterCriteria,
        })
        .then((res) => {
          this.jobList = res.data.data;
        });
    },
    deletePopup(item) {
      this.showDeleteModal = true;
      this.id = item.id;
    },
    cancel() {
      this.showDeleteModal = false;
      this.id = null;
    },
    reloadResource() {
      this.cancel();
      this.getJobList();
    },
    filter(f) {
      this.filterCriteria = f;
    },
  },
  watch: {
    filterCriteria: {
      handler() {
        this.getJobList();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.table-heading {
  background-color: #1d2b58;
}
.table-heading th {
  color: #fff;
}
.preview {
  border-radius: 5px;
  padding: 5px 20px;
}
</style>
